.form__create-enterprise {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.form__title {
  margin: 0 auto 15px;
  border-bottom: 1px solid #000;
}
.form__input {
  background-color: #f3f4f6;
  border: 1px solid #425361;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 16px;
  height: 36px;
  outline: none;
  padding: 2px 8px;
  transition: background-color 0.3s ease-in-out;
  width: 100%;
}

.label {
  margin-bottom: 5px;
}

.form__span_enterprice {
  width: 100%;
  color: red;
  height: 21px;
  font-size: 11px;
}

.container {
  display: flex;
}

.container__right {
  width: 50%;
  padding: 5px;
}

.container__left {
  width: 50%;
  padding: 5px;
}

.form__inpyt_row {
  display: flex;
  gap: 5px;
  padding-bottom: 5px;
}
