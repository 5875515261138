.notfound {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  justify-content: center;
  flex-direction: column;
  background-color: #f0f0f0;
}

.notfound__block {
  display: flex;
  align-items: center;
  background-image: url('../../untils/img/travolta2-min.gif');
  height: 70vh;
  width: 90vw;
  background-repeat: no-repeat;
  flex-direction: column;
  background-position: center;
}

.notfound__title {
  color: white;
  font-size: 30px;
  margin-top: 80px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); /* Добавляем тень */
}

.notfound__subtitle {
  color: white;
  font-size: 30px;
}

.notfound_type_to-main {
  color: #000;
  font-size: 25px;
  font-weight: 800;
}

.notfound__link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-top: 15px;
}

.notfound__link:hover {
  background-color: #0056b3;
}
