.button_del {
  background-image: url('../../untils/img/dark-blue-icon-close.svg');
  background-color: inherit;
  width: 20px;
  height: 20px;
  background-size: auto;
  background-repeat: no-repeat;
  cursor: pointer;
  background-position: center;
}

.button_del:hover {
  scale: 1.1;
}
