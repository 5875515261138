.modal__overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(51, 64, 75, 0.774);
  color: rgba(66, 83, 97, 1);
  position: fixed;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__overlay-disabled {
  display: none;
}

.modal__window {
  padding: 20px;
  width: fit-content;
  height: fit-content;
  background-color: #fff;
  border-radius: 4px;
  position: relative;
  max-height: 80vh;
}

.button_close {
  position: absolute;
  top: -23px;
  right: -30px;
  width: 22px;
  height: 22px;
  border: none;
  background-color: transparent;
  background-image: url('../../untils/img/dark-blue-icon-close.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.modal-form {
  margin-bottom: 10px;
}
.modal-form__list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 10px;
}
.modal-form__item {
  font-weight: 700;
}
.modal-form__item span {
  font-weight: 400;
}
.modal-form__select-input {
  margin-left: 5px;
  color: inherit;
  padding: 8px;
  border: 1px solid var(--line-color);
  border-radius: var(--radius);
  font-size: 14px;
  font-family: Segoe UI;
}
.modal-form__button {
  width: 100%;
  padding: 10px;
  border-radius: var(--radius);
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}
.modal-form__button:hover {
  opacity: 0.6;
}
.modal-form__button.save {
  background-color: var(--accent-green);
}
.modal-form__button.close {
  background-color: var(--main-color);
}

.form__input.input {
  width: 100%;
}
.form__input.id {
  width: 150px;
}
.profession {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

/*селект*/
.modal-form__select-wrapper {
  position: relative;
  cursor: pointer;
  display: inline-block;
}
.modal-form__select-wrapper select {
  -webkit-appearance: none; /* Chrome */
  -moz-appearance: none; /* Firefox */
  appearance: none;
  /* display: block; */
  /*  */
}
.modal-form__select-wrapper::after {
  content: '';
  position: absolute;
  right: 20px;
  bottom: 50%;
  width: 10px;
  height: 8px;
  display: block;
  /* background: #fff url('../../../img/checkMark.svg') no-repeat center / cover; */
  transform: translateY(50%);
  transition: 0.2s ease-in;
  pointer-events: none;
  z-index: 1;
}

/* .modal-form__select-wrapper.active::after {
  transform: translateY(50%) rotate(180deg);
} */

.modal-form__select-input {
  min-width: 250px;
  transition: border-color 0.3s ease-in-out;
}
.modal-form__select-input:hover {
  border-color: var(--main-color);
}
.modal-form__select-input:focus {
  outline: none;
}
.modal-form__select-input:focus-visible {
  border-color: var(--accent-green);
}
.modal-form__select-input:active {
  border-color: var(--accent-green);
}

.button_close-bottom {
  border-radius: 5px;
  width: 100%;
  background-color: #425361;
  border: none;
  color: #fff;
  height: 38px;
  font-family: inherit;
  font-size: 18px;
  font-weight: 500;
  margin-top: 15px;
}
