.main-frame {
  display: flex;
}

.main-frame__content {
  width: 100%;
}

.aside__menu {
  height: 100vh;
  width: 300px;
  background-color: #f3f4f6;
  position: relative;
}

.aside__nav {
  padding: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  color: var(--main-color);
}

.aside__link {
  text-decoration: none;
  color: var(--main-color);
  font-weight: 700;
}

.aside__link:hover {
  scale: 1.002;
}

.active {
  text-transform: uppercase;
}

.aside__button_go-back {
  position: absolute;
  bottom: 20px;
  text-decoration: none;
  padding: 5px;
  background-color: #425361;
  color: #f3f4f6;
  width: 80%;
  text-align: center;
  border-radius: var(--radius);
  font-size: 18px;
  margin: 0 auto;
  right: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}