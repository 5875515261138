/********форма*********/
.form {
  display: flex;
  column-gap: 10px;
  width: 1682px;
  margin: auto;
}

.form__block-left {
  max-width: 410px;
}

.form__block-center {
  max-width: 747px;
}

.form__block-right {
  max-width: 747px;
}

/*-----заголовки секций-----*/

.form__header {
  background-color: var(--accent-light-color);
  height: 70px;
  box-shadow: var(--heavy-shadow);
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
  display: flex;
  align-items: center;
  justify-content: center;
}
.form__header-title {
  font-size: 24px;
  font-weight: 700;
}
.left {
  flex-direction: column;
}

/*-----секция Профессия-----*/

.section {
  box-shadow: var(--light-shadow);
  border-radius: var(--radius);
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
}
.section.profess {
  max-width: 410px;
  gap: 10px;
}

.label-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button.copy {
  background-color: var(--accent-green);
  margin-top: 10px;
  width: 100%;
}
.spoiler-wrapper {
  display: flex;
  /* gap: 10px; */
  width: 100%;
}

.input-order__wrapper:nth-child(1) {
  border-right: 1px solid var(--line-color);
  padding-right: 10px;
}
.input-order__wrapper:nth-child(2) {
  padding-left: 10px;
}

/*-----секция Кнопки-----*/
.section.buttons {
  gap: 10px;
}

.total {
  text-transform: uppercase;
  align-self: flex-start;
  border-bottom: 1px solid var(--line-color);
  width: 100%;
  padding-bottom: 3px;
}

.buttons_wrapper {
  display: flex;
  gap: 10px;
}

.button {
  padding: 3px;
  border-radius: var(--radius);
  border: none;
  color: white;
  font-family: inherit;
  font-size: 18px;
  font-weight: 500;
}

.button__table {
  background-color: var(--accent-dark-blue);
  width: 100%;
  position: relative;
  font-size: 15px;
}
.button__table:disabled {
  opacity: 0.4;
}

.button:hover {
  text-decoration: underline 1px;
}
.button.send {
  background-color: var(--accent-green);
  width: 100%;
}

.button.send:disabled {
  opacity: 0.4;
}

.button.reset {
  background-color: var(--accent-red);
  width: 100%;
}
.button.button-cancel {
  background: transparent;
  color: var(--accent-red);
  font-size: 16px;
  height: 20px;
}

/*-----секция Риски-----*/

.risk,
.label.box {
  display: flex;
  flex-direction: column;
}

.label.box.comments {
  width: 100%;
}

.risk {
  width: 100%;
  justify-self: center;
  box-sizing: border-box;
  height: fit-content;
  padding: 10px;
}

.risk__labels {
  gap: 10px;
}

.label__box {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  column-gap: 10px;
  margin-bottom: 15px;
}

.label.box {
  align-items: center;
}

.label.risk__attitude {
  display: block;
  font-size: 16px;
}

.label.risk__attitude.risk__attitude-right {
  width: 485px;
  height: 50px;
}

.buttons_wrapper {
  margin-top: 15px;
  margin-bottom: 15px;
}

/*----секция План-----*/

.plan {
  background-color: var(--accent-light-color);
  border-radius: var(--radius);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 20px 50px;
}
.plan__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 18px;
}
.form__input.plan-input {
  width: 100%;
  background-color: #fff;
  border: none;
}
.form__input.input {
  text-align: left;
}
.input {
  text-align: center;
}
.form__input.input.ipr {
  padding: 0 8px;
  line-height: var(--input-height);
}

.history {
  display: flex;
  flex-direction: column;
}

.history__span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 13px;
  cursor: pointer;
  margin-bottom: 5px;
}

.form__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
}

.form__input {
  box-sizing: border-box;
  height: 30px;
  padding: 2px 8px;
  border: 1px solid var(--main-color);
  border-radius: 4px;
  outline: none;
  font-family: inherit;
  font-size: 16px;
  background-color: rgba(243, 244, 246, 1);
  transition: background-color 0.3s ease-in-out;
}

.form__input-opr {
  width: 50px;
}

.form__input:hover {
  background-color: rgb(222, 228, 239);
}
.form__input:focus {
  box-shadow: 0px 0px 0px 1px var(--main-color);
}

.submit {
  margin-top: 20px;
}

.label {
  font-family: inherit;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  width: 100%;
}

.label__opr {
  width: max-content;
}

.label.order-input {
  width: 100%;
}

.label.profession {
  align-items: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.wrapper_text {
  color: rgba(66, 83, 97, 1);
  padding-bottom: 6px;
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(66, 83, 97, 0.2);
  font-size: 16px;
  font-weight: 500;
  width: 100%;
}

/********чекбокс*********/
.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}

.checkbox__label {
  width: max-content;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--accent-dark-blue);
  margin-top: 4px;
}

.form__pseudo-checkbox {
  background-color: rgba(243, 244, 246, 1);
  border: 1px solid rgba(66, 83, 97, 1);
  border-radius: 4px;
  display: inline-block;
  width: var(--input-height);
  height: var(--input-height);
  margin-right: 5px;
  vertical-align: sub;
  position: relative;
}

.form__pseudo-checkbox::before {
  content: '';
  /*рисунок*/
  display: inline-block;
  width: var(--input-height);
  height: var(--input-height);
  background-image: url('../../../img/icon__yes.svg');
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
}
/*checked*/
.form__checkbox:checked + span {
  background-color: rgba(66, 83, 97, 1);
}
.form__checkbox:checked + .form__pseudo-checkbox::before {
  opacity: 1;
}
.checkbox__label-text {
  font-family: inherit;
  font-size: 18px;
  font-weight: 700;
}
.checkbox__label.disabled > span {
  color: rgba(66, 83, 97, 0.6);
  border-color: rgba(66, 83, 97, 0.6);
}

.label__checkbox {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.label.profs {
  width: 90%;
  padding: 0 5%;
}

.label__job {
  display: flex;
  align-items: center;
}

.form__block_job {
  display: flex;
  column-gap: 5px;
}

.form__input_small {
  width: 70px;
  margin-left: 5px;
}

.center-block {
  width: 343px;
}

.right-block-right {
  width: 205px;
}

.right-block-left {
  width: 239px;
}
