.logs {
  margin-top: 15px;
  margin-left: 25px;
  max-width: 1280px;
}

.logs__container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.logs_event:nth-child(even) {
  background-color: cadetblue;
}