.div:hover {
  border: 1px solid black;
}

.block {
  width: 100%;
  max-height: 300px;
  overflow: auto;
  position: absolute;
  background-color: #f3f4f6;
  z-index: 1;
  top: 35px;
  border-radius: 5px;
}

.block__select {
  padding: 5px;
}

.block__select:hover {
  background-color: var(-line-color);
  border: 1px solid black;
  border-radius: 5px;
}

.serchBox {
  display: flex;
  position: relative;
  width: 100%;
}
.serchBox__input {
  padding: 5px;
  width: 100%;
  border-radius: 4px;
  min-height: 30px;
  background-color: #f3f4f6;
  outline: none;
  padding: 2px 8px;
}
