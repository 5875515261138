.profile {
  display: flex;
}

.entry__form_profile {
  display: flex;
  flex-direction: column;
  width: 360px;
  align-items: center;
}

.profile__button {
  background-color: var(--accent-dark-blue);
  margin-top: 10px;
}

.profile__button:disabled {
  opacity: 0.2;
}

.question__button.profile {
  color: var(--accent-red);
  border: none;
}

.span-error {
  color: red;
  font-size: 12px;
  height: 32px;
}
.span-error-success {
  color: green;
  font-size: 12px;
  height: 32px;
}

.profile__enterprise-list {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  text-align: center;
  width: 80%;
  margin: 15px auto 0;
}

.profile__enterprise-list_box {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, 282px);
  padding: 10px;
}

.profile-block {
  width: 90%;
}