.worker-places {
  margin: 10px;
}
.siz__container {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.siz__info {
  font-size: 13px;
}

.siz__title {
  text-align: left;
}

.detalis__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.detalis__title {
  display: flex;
  flex-direction: column;
}

.history__container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: 60vh;
  overflow: auto;
  border-radius: 10px;
  border: 1px solid black;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.history__info {
  max-width: max-content;
  cursor: pointer;
}

.history__info:hover {
  scale: 0.95;
}
.button__new {
  display: block;
  margin: auto;
}
