.main__card {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  padding: 8px 15px;
  cursor: pointer;
  border-top: 1px solid var(--light-line-color);
  font-weight: 400;
  font-size: 18px;
  color: var(--accent-dark-blue);
  text-align: center;
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
}

.main__card.close {
  display: none;
}
.main__card:hover {
  background-color: var(--line-color);
  color: #fff;
}
