*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

button {
  border: none;
}
a {
  text-decoration: none;
}

:root {
  --main-color: rgba(66, 83, 97, 1);
  --accent-green: #17cb6c;
  --accent-red: #fe3f57;
  --accent-dark-blue: #425361;
  --accent-light-color: #f3f4f6;
  --line-color: #8e98a0;
  --radius: 5px;
  --heavy-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --light-shadow: 0px 4px 23px 3px rgba(0, 0, 0, 0.11);
  --input-height: 36px;
  --button-height: 38px;
}
.App {
  display: block;
}
.main {
  margin: 30px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: var(--main-color);
  flex: 1 0 auto;
}

.button_default {
  text-decoration: none;
  padding: 5px;
  color: #f3f4f6;
  width: 80%;
  text-align: center;
  border-radius: var(--radius);
  font-size: 18px;
  margin: 0 auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.button_default:disabled {
  opacity: 0.4;
}

.button_color-darck {
  background-color: #425361;
}

.button_color-green {
  background-color: var(--accent-green);
}

.main-menu {
  display: flex;
}

.nav-bar {
  margin: 10px;
  display: flex;
}
