.button-back {
  padding: 4px;
  border-radius: var(--radius);
  border: none;
  color: white;
  font-family: inherit;
  font-size: 18px;
  font-weight: 500;
  background-color: var(--accent-dark-blue);
  width: 100%;
  position: relative;
  text-align: center;
}
